<template>
  <div class="distribution-container">
    <el-row class="header" type="flex" justify="start">
      <el-col :span="6" class="top-search">
        <div class="search-name">创建人类型:</div>
        <el-select v-model="creator_type" @change="changeType">
          <el-option value="" label="全部"></el-option>
          <el-option :value="1" label="平台"></el-option>
          <el-option :value="2" label="开发者"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="top-search">
        <div class="search-name">应用名称:</div>
        <el-input v-model="app_name" placeholder="请输入应用名称" size="normal"></el-input>
      </el-col>
      <el-col :span="6" :offset="1" class="top-search">
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="primary" @click="handleCreate">+创建计划</el-button>
        <el-button @click="handleExport">导出</el-button>
      </el-col>
    </el-row>

    <div class="content">
      <el-table :data="list" border stripe>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="操作" width="260" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleInfo(scope.row)" >
              查看
            </el-button>
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="showOption(scope.row)">
              编辑
            </el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)" v-if="showOption(scope.row)">
              删除
            </el-button>
            <p></p>
            <el-button size="mini" type="primary" @click="handleUser(scope.row)">
              客户明细
            </el-button>
            <el-button size="mini" type="primary" @click="handleRecord(scope.row)">
              奖励明细
            </el-button>
            <el-button size="mini" type="success" @click="handleSort(scope.row)">
              设置权重
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :title="'权重'"
      :visible.sync="showSort"
      width="30%"
      @close="showSort = false"
    >
      <el-form ref="form" :model="sortForm.weight" label-width="80px">
        <el-form-item label="权重">
          <el-input v-model="sortForm.weight"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showSort = false">取 消</el-button>
        <el-button type="primary" @click="submitSort">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { deletePlan, setPlanWeight, getPlanList, exportPlan } from "@/api/developer.js";
import {PlanCreatorType,AppRewardType} from "@/utils/helper.js";

export default {
  props: {},
  data() {
    return {
      list:[],
      total:0,
      creator_type: "",
      app_name: "",
      currentPage: 1,
      pageSize: 20,
      postForm: {},
      sortForm: {},
      columns: [
        {
          id: "creator_type",
          label: "创建人类型",
          formatter: (row) => {
            return PlanCreatorType[row.creator_type];
          },
        },
        {
          id: "creator_nickname",
          label: "创建人昵称",
        },
        {
          id: "weight",
          label: "权重",
        },
        {
          id: "name",
          label: "计划名称",
        },
        {
          id: "app_name",
          label: "应用名称",
        },
        {
          id: "reward_type",
          label: "奖励方式",
          formatter: (row) => AppRewardType[row.reward_type],
        },
        {
          id: "user_count_today",
          label: "今日客户",
        },
        {
          id: "user_count_total",
          label: "累计客户",
        },
        {
          id: "reward_count_today",
          label: "今日奖励数",
        },
        {
          id: "reward_count_total",
          label: "累计奖励数",
        },
        {
          id: "created_at",
          label: "创建时间",
        },
        {
          id: "effective_start_at",
          label: "生效时间",
          formatter: (row) => `${row.effective_start_at} ~ ${row.effective_end_at}`,
        },
      ],
      showSort: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // 初始化
    this.getDistributionList();
  },
  methods: {
    async getDistributionList() {
      const params = this.generateQueryParams();
      try {
        const { data } = await getPlanList(params);
        this.list = data.data.data_list;
        this.total = data.data.total_num;
      } catch (error) {
        console.log(error);
      }
    },
    search(){
      this.currentPage = 1;
      this.getDistributionList();
    },
    // 生成查询参数
    generateQueryParams() {
      const { creator_type, app_name } = this;
      const params = {
        page: this.currentPage,
        page_size: this.pageSize,
        creator_type: creator_type,
        app_name: app_name,
      };
      return params;
    },
    async getExoprt() {
      const { creator_type, app_name } = this;
      const params = {
        creator_type: creator_type,
        app_name: app_name,
        }
      try {
        const { data } = await exportPlan(params);
         data.data?window.open(data.data):this.$message.error("暂无下载链接");
        } catch (error) {
          console.log(error);
        }
    },
    handleExport() {
      this.getExoprt();
    },
    changeType(val) {
      this.creator_type = val;
    },
    showOption(data) {
      dayjs.extend(utc);
      return dayjs.utc().isBefore(dayjs(data.effective_start_at).startOf("day"));
    },
    handleInfo(data) {
      const url = `/distribution/case/info?id=${data.id}`;
      this.$router.push(url);
    },
    handleEdit(data) {
      const url = `/distribution/case/edit?id=${data.id}`;
      this.$router.push(url);
    },
    handleSort(data) {
       this.sortForm = {
        id: data.id,
        weight: data.weight,
      };
      this.showSort = true;
    },
    async submitSort(){
      try {
          const { data } = await setPlanWeight(this.sortForm);
          this.showSort = false;
          this.$message({
              type: 'success',
              message: '编辑成功!'
            });
          this.getDistributionList();
        } catch (error) {
          console.log(error);
        }
    },
    async deletePlan(id){
        try {
            const { data } =await deletePlan(id);
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getDistributionList();
          } catch (error) {
            console.log(error);
          }
    },
    handleDelete(id){
      this.$confirm('此操作将删除该计划, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deletePlan(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    handleUser(data) {
      const url = `/distribution/case/user?id=${data.id}`;
      this.$router.push(url);
    },
    handleRecord(data) {
      const url = `/distribution/case/record?id=${data.id}&type=${data.reward_type}`;
      this.$router.push(url);
    },
    handleCreate() {
      const url = "/distribution/case/create";
      this.$router.push(url);
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getDistributionList();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getDistributionList();
    },
  },
};
</script>

<style lang="less" scoped>
.distribution-container {
  width: 100%;

  .header {
    padding: 20px;
  }
  .top-search {
    display: flex;
    align-items: center;
    .search-name {
      word-break: keep-all;
      margin-right: 10px;
    }
  }

  .search-input {
    width: 300px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    padding: 0 20px 20px;
  }
}
</style>
